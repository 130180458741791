import {
  URLAPI,
  DEPARTURES_PATH,
  DEPARTURES_EMERGENCY_CREATE_PATH,
  DEPARTURES_SCHEDULED_CREATE_PATH,
  DEPARTURES_UPDATE_PATH,
  DEPARTURES_APPROVED_DENIED_PATH,
  DEPARTURES_REASONS_PATH,
  DEPARTURES_REASONS_CREATE_PATH,
  DEPARTURES_REASONS_UPDATE_PATH,
  DEPARTURES_REASONS_TYPES_PATH,
  DEPARTURE_DETAILS_PATH,
  DEPARTURES_RECURRENT_CREATE_PATH,
  ABSENCE_DAYS_PATH,
  DEPARTURES_ACCEPT_DECLINE_PATH,
  DEPARTURES_REPORT_PATH,
  DEPARTURES_REPORT_CHECK_PATH,
  DEPARTURES_STATISTICS_PATH,
  DEPARTURES_STATISTICS_YEAR_PATH,
} from "../config";
import { LOGOUT } from "./users";
import axios from "axios";

const init = {
  _get_all_departures: {
    data: [],
    status: 0,
    message: "",
  },
  _get_all_departures_statistics: {
    data: [],
    status: 0,
    message: "",
  },
  _get_all_departures_reasons: {
    data: [],
    status: 0,
    message: "",
  },
  _get_all_departures_reasons_types: {
    data: [],
    status: 0,
    message: "",
  },
  _get_departure_details: {
    data: {},
    status: 0,
    message: "",
  },
  _create_emergency_departure: {
    data: {},
    status: 0,
    message: "",
  },
  _create_scheduled_departure: {
    data: {},
    status: 0,
    message: "",
  },
  _create_recurrent_departure: {
    data: {},
    status: 0,
    message: "",
  },
  _update_departure: {
    data: {},
    status: 0,
    message: "",
  },
  _update_departure_reason: {
    data: {},
    status: 0,
    message: "",
  },
  _create_departure_reason: {
    data: {},
    status: 0,
    message: "",
  },
  _get_notification: {
    data: [],
  },
  _get_report: {
    data: {},
    status: 0,
    message: "",
  },
};

export const GET_ALL_DEPARTURES = "GET_ALL_DEPARTURES";
export const GET_ALL_DEPARTURES_STATISTICS = "GET_ALL_DEPARTURES_STATISTICS";
export const GET_DEPARTURE_DETAILS = "GET_DEPARTURE_DETAILS";
export const GET_ALL_DEPARTURES_REASONS = "GET_ALL_DEPARTURES_REASONS";
export const UPDATE_DEPARTURE_REASON = "UPDATE_DEPARTURE_REASON";
export const CREATE_DEPARTURE_REASON = "CREATE_DEPARTURE_REASON";
export const GET_ALL_DEPARTURES_REASONS_TYPES =
  "GET_ALL_DEPARTURES_REASONS_TYPES";
export const CREATE_EMERGENCY_DEPARTURE = "CREATE_EMERGENCY_DEPARTURE";
export const CREATE_SCHEDULED_DEPARTURE = "CREATE_SCHEDULED_DEPARTURE";
export const CREATE_RECURRENT_DEPARTURE = "CREATE_RECURRENT_DEPARTURE";
export const UPDATE_DEPARTURE = "UPDATE_DEPARTURE";
export const ABSENCE_DAYS = "ABSENCE_DAYS";
export const GET_REPORT_DEPARTURE = "GET_REPORT_DEPARTURE";

// export const GET_NOTIFICATION = "GET_NOTIFICATION";
// export const GET_REPORT = "GET_REPORT"

export const departures_module = (state = init, action) => {
  switch (action.type) {
    case GET_ALL_DEPARTURES:
      return {
        ...state,
        _get_all_departures: action.payload,
      };
    case GET_ALL_DEPARTURES_STATISTICS:
      return {
        ...state,
        _get_all_departures_statistics: action.payload,
      };
    case GET_ALL_DEPARTURES_REASONS:
      return {
        ...state,
        _get_all_departures_reasons: action.payload,
      };
    case GET_ALL_DEPARTURES_REASONS_TYPES:
      return {
        ...state,
        _get_all_departures_reasons_types: action.payload,
      };
    case GET_DEPARTURE_DETAILS:
      return {
        ...state,
        _get_departure_details: action.payload,
      };
    case CREATE_EMERGENCY_DEPARTURE:
      return {
        ...state,
        _create_emergency_departure: action.payload,
      };
    case CREATE_SCHEDULED_DEPARTURE:
      return {
        ...state,
        _create_scheduled_departure: action.payload,
      };
    case CREATE_RECURRENT_DEPARTURE:
      return {
        ...state,
        _create_recurrent_departure: action.payload,
      };
    case UPDATE_DEPARTURE:
      return {
        ...state,
        _update_departure: action.payload,
      };
    case UPDATE_DEPARTURE_REASON:
      return {
        ...state,
        _update_departure_reason: action.payload,
      };
    case CREATE_DEPARTURE_REASON:
      return {
        ...state,
        _create_departure_reason: action.payload,
      };
    // case GET_NOTIFICATION:
    //     return {
    //         ...state,
    //         _get_notification: action.payload,
    //     };

    case GET_REPORT_DEPARTURE:
      return {
        ...state,
        _get_report: action.payload
    };
    case LOGOUT:
      return init;
    default:
      return state;
  }
};

/**
 * @function getAllDepartures
 * @description Obtiene las departureas haciendo una peticion a la API
 * @param {string} token - token de autenticacion
 * @async @return dispatch - dispatch de la accion
 */
export const getAllDepartures = (token, page) => async (dispatch) => {
  try {
    const response = await axios.get(`${URLAPI}${DEPARTURES_PATH}?page=${page}`,  {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_DEPARTURES,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: GET_ALL_DEPARTURES,
        payload: {
          data: [],
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: GET_ALL_DEPARTURES,
        payload: {
          data: [],
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};

export const getAllDeparturesStatistics = (token) => async (dispatch) => {
  try {
    const response = await axios.get(`${URLAPI}${DEPARTURES_STATISTICS_PATH}`,  {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_DEPARTURES_STATISTICS,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: GET_ALL_DEPARTURES_STATISTICS,
        payload: {
          data: [],
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: GET_ALL_DEPARTURES_STATISTICS,
        payload: {
          data: [],
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};

export const getDepartureDetails = (token, id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${URLAPI}${DEPARTURE_DETAILS_PATH}${id}/`,
      { headers: { Authorization: `Token ${token}` } }
    );
    dispatch({
      type: GET_DEPARTURE_DETAILS,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (err) {
    console.log(`ERROR:${err}`);
    throw err;
  }
};

export const getAllDeparturesReasons = (token) => async (dispatch) => {
  try {
    const response = await axios.get(`${URLAPI}${DEPARTURES_REASONS_PATH}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_DEPARTURES_REASONS,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: GET_ALL_DEPARTURES_REASONS,
        payload: {
          data: [],
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: GET_ALL_DEPARTURES_REASONS,
        payload: {
          data: [],
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};

export const getAllDeparturesReasonsTypes = (token) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${URLAPI}${DEPARTURES_REASONS_TYPES_PATH}`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    dispatch({
      type: GET_ALL_DEPARTURES_REASONS_TYPES,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: GET_ALL_DEPARTURES_REASONS_TYPES,
        payload: {
          data: [],
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: GET_ALL_DEPARTURES_REASONS_TYPES,
        payload: {
          data: [],
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};

export const createEmergencyDeparture = (token, data) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${URLAPI}${DEPARTURES_EMERGENCY_CREATE_PATH}`,
      data,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    dispatch({
      type: CREATE_EMERGENCY_DEPARTURE,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: CREATE_EMERGENCY_DEPARTURE,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: CREATE_EMERGENCY_DEPARTURE,
        payload: {
          data: {},
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};

export const createScheduledDeparture = (token, data) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${URLAPI}${DEPARTURES_SCHEDULED_CREATE_PATH}`,
      data,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    dispatch({
      type: CREATE_SCHEDULED_DEPARTURE,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: CREATE_SCHEDULED_DEPARTURE,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: CREATE_SCHEDULED_DEPARTURE,
        payload: {
          data: {},
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};

export const createRecurentDeparture = (token, data) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${URLAPI}${DEPARTURES_RECURRENT_CREATE_PATH}`,
      data,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    dispatch({
      type: CREATE_RECURRENT_DEPARTURE,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: CREATE_RECURRENT_DEPARTURE,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: CREATE_RECURRENT_DEPARTURE,
        payload: {
          data: {},
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};

//update departure
export const updateDepartureRequired = (token, id, data) => async (dispatch) => {
    try {
      const response = await axios.patch(
        `${URLAPI}${DEPARTURES_UPDATE_PATH}${id}/`,
        data,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      dispatch({
        type: UPDATE_DEPARTURE,
        payload: {
          data: response.data,
          status: response.status,
          message: response.data.mensaje,
        },
      });
    } catch (error) {
      if (error.response != null) {
        dispatch({
          type: UPDATE_DEPARTURE,
          payload: {
            data: {},
            status: error.response.status,
            message: error.response.data.mensaje,
          },
        });
      } else {
        dispatch({
          type: UPDATE_DEPARTURE,
          payload: {
            data: {},
            status: 500,
            message: "Error en el servidor",
          },
        });
      }
    }
  };

export const ApprovedDeniedDeparture = (token, id, data) => async (dispatch) => {
    try {
      const response = await axios.patch(
        `${URLAPI}${DEPARTURES_APPROVED_DENIED_PATH}${id}/`,
        data,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      dispatch({
        type: UPDATE_DEPARTURE,
        payload: {
          data: response.data,
          status: response.status,
          message: response.data.mensaje,
        },
      });
    } catch (error) {
      if (error.response != null) {
        dispatch({
          type: UPDATE_DEPARTURE,
          payload: {
            data: {},
            status: error.response.status,
            message: error.response.data.mensaje,
          },
        });
      } else {
        dispatch({
          type: UPDATE_DEPARTURE,
          payload: {
            data: {},
            status: 500,
            message: "Error en el servidor",
          },
        });
      }
    }
  };

export const AceptDeclineDeparture = (token, id, data) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${URLAPI}${DEPARTURES_ACCEPT_DECLINE_PATH}${id}/`,
      data,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    dispatch({
      type: UPDATE_DEPARTURE,
      payload: {
        data: response.data,
        status: response.status,
        message: response.data.mensaje,
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: UPDATE_DEPARTURE,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: UPDATE_DEPARTURE,
        payload: {
          data: {},
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};

export const update_absence_days = (token, id, data) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${URLAPI}${ABSENCE_DAYS_PATH}${id}/`,
      data,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    dispatch({
      type: UPDATE_DEPARTURE,
      payload: {
        data: response.data,
        status: response.status,
        message: response.data.mensaje,
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: UPDATE_DEPARTURE,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: UPDATE_DEPARTURE,
        payload: {
          data: {},
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};

export const updateDepartureReason = (token, id, data) => async (dispatch) => {
    try {
      const response = await axios.patch(
        `${URLAPI}${DEPARTURES_REASONS_UPDATE_PATH}${id}/`,
        data,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      dispatch({
        type: UPDATE_DEPARTURE_REASON,
        payload: {
          data: response.data,
          status: response.status,
          message: response.data.mensaje,
        },
      });
    } catch (error) {
      if (error.response != null) {
        dispatch({
          type: UPDATE_DEPARTURE_REASON,
          payload: {
            data: {},
            status: error.response.status,
            message: error.response.data.mensaje,
          },
        });
      } else {
        dispatch({
          type: UPDATE_DEPARTURE_REASON,
          payload: {
            data: {},
            status: 500,
            message: "Error en el servidor",
          },
        });
      }
    }
  };

export const createDepartureReason = (token, data) => async (dispatch) => {
    try {
      const response = await axios.post(
        `${URLAPI}${DEPARTURES_REASONS_CREATE_PATH}`,
        data,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      dispatch({
        type: CREATE_DEPARTURE_REASON,
        payload: {
          data: response.data,
          status: response.status,
          message: "",
        },
      });
    } catch (error) {
      if (error.response != null) {
        dispatch({
          type: CREATE_DEPARTURE_REASON,
          payload: {
            data: {},
            status: error.response.status,
            message: error.response.data.mensaje,
          },
        });
      } else {
        dispatch({
          type: CREATE_DEPARTURE_REASON,
          payload: {
            data: {},
            status: 500,
            message: "Error en el servidor",
          },
        });
      }
    }
  };

export const get_report = (data, token) => async (dispatch) => {
     try {
         const res = await axios.post(URLAPI + DEPARTURES_REPORT_PATH, data, {
             headers: {
                 Authorization: `Token ${token}`,
             },
         })

         dispatch({
             type: GET_REPORT_DEPARTURE,
             payload: {
                 data: res.data,
                 status: res.status,
                 message: ""
             }
         })
     } catch (e) {
         dispatch({
             type: GET_REPORT_DEPARTURE,
             payload: {
                 data: {},
                 status: 500,
                 message: "Ocurrio un error al generar el reporte"
             }
         })
     }
}

export const searchStatisticYear = async (token, params) => {
  return await axios.get(`${URLAPI}${DEPARTURES_STATISTICS_YEAR_PATH}${params}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

// export const save_notification = (data) => async (dispatch) => {

//     dispatch({
//         type: GET_NOTIFICATION,
//         payload: {
//             data: data,
//         }
//     });
// }

//clear departure
export const clearDeparture = (type) => (dispatch) => {
  dispatch({
    type: type,
    payload: {
      data: {},
      status: 0,
      message: "",
    },
  });
};

export const check_export_departures_status = async (token, task_id) => {
     return await axios.get(`${URLAPI}${DEPARTURES_REPORT_CHECK_PATH}${task_id}/`, {
         headers: {
             Authorization: `Token ${token}`,
         },
     }) 
}
