import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import Breadcrumbs from "../../../components/breadcrumbs";
import { getAllDeparturesStatistics, searchStatisticYear } from "../../../redux/departures";
import {Chart} from "react-google-charts";
import SelectInput from "../../../components/select";

function data_years() {
    const year = new Date().getFullYear();
    let data = [] 
    for (var i = year - 4; i <= year; i++) {
        data.push({'id': i, 'name': i});
    }
    return data;
}

const Statistics = () => {
    const dispatch = useDispatch();

    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const _get_all_departures_statistics = useSelector((state) => state.departures_module._get_all_departures_statistics);

    let date = new Date();
    const [datas, setDatas] = useState([]);
    const [data, setData] = useState([]);
    const [year, setYear] = useState(date.getFullYear());
    const [scheduled, setScheduled] = useState([]);

    const years = data_years();
    
    useEffect(() => {
        
        dispatch(getAllDeparturesStatistics(token));
    }, [token, dispatch]);

    useEffect(() => {
        if (_get_all_departures_statistics && _get_all_departures_statistics.data && _get_all_departures_statistics.data.datos) {
            setDatas(_get_all_departures_statistics.data.datos);
            setScheduled(_get_all_departures_statistics.data.programadas);
        }
    }, [_get_all_departures_statistics]);

    useEffect(() => {
        // document.getElementById('anio').selectedIndex = '2025';
        get_statistics_year(year);
        // eslint-disable-next-line
    }, [year]);

    async function get_statistics_year(year) {
        var params = '?anio=' + year;
        const response = await searchStatisticYear(token, params);
        if (response.status === 200) {
            setData(response.data.datos);
        }
    }

    var options = {
        width: '100%',
        height: 600,
        chart: {
            title: 'Clientes',
        },
        legend: { position: 'right' },
        bar: { groupWidth: '90%' },
        isStacked: true,
    };

    const filterYear = (e) => {
        setYear(e.target.value);
    }

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Estadísticas"
                            items={[{label: "Estadísticas", link: "/estadisticas"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="mt-1">
                                    <h5>Salidas anuales</h5>
                                    <div className="row">
                                        <div className="col-md-3 col-sm-6 col-12 mt-md-1">
                                            <label>Año</label>
                                            <SelectInput
                                                id="year"
                                                name="year"
                                                options={years}
                                                onChange={filterYear}
                                                select={year}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <Chart
                                            chartType="ColumnChart"
                                            data={data}
                                            options={options}
                                        />
                                    </div>
                                </div>
                                <div className="mt-1 mb-2">
                                    <h5>Empleados fuera del establecimiento</h5>
                                    <div className="table-responsive mt-1">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Identificación</th>
                                                    <th className="text-center">Empleado</th>
                                                    <th className="text-center">Establecimiento</th>
                                                    <th className="text-center">Zona</th>
                                                    <th className="text-center">Fecha</th>
                                                    <th className="text-center">Motivo</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {datas.length > 0 &&
                                                    datas.map(x => (
                                                        <tr>
                                                            <td>{x.identificacion}</td>
                                                            <td>{x.empleado}</td>
                                                            <td>{x.establecimiento}</td>
                                                            <td>{x.zona}</td>
                                                            <td>{x.fecha}</td>
                                                            <td>{x.motivo} ({x.detalle})</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="mt-1 mb-2">
                                    <h5>Salidas programadas actuales</h5>
                                    <div className="table-responsive mt-1">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Identificación</th>
                                                    <th className="text-center">Empleado</th>
                                                    <th className="text-center">Establecimiento</th>
                                                    <th className="text-center">Zona</th>
                                                    <th className="text-center">Estado</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {scheduled.length > 0 &&
                                                    scheduled.map(s => (
                                                        <tr>
                                                            <td>{s.identificacion}</td>
                                                            <td>{s.empleado}</td>
                                                            <td>{s.establecimiento}</td>
                                                            <td>{s.zona}</td>
                                                            <td>{s.estado}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Statistics;